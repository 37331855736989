import Vue from 'vue'
import router from './router'
import App from './App.vue'
import 'vant/lib/index.less'
import store from './store/index.js'

import { SwipeCell, Popup, Picker, SubmitBar, Step, Steps, Loading, Form, Field, Button, Col, Row, Image as VanImage, NavBar, List, Cell, Tag, Tab, Tabs, Icon, ActionSheet, Divider, Tabbar, TabbarItem, RadioGroup, Radio, Calendar, Checkbox, Overlay, Card } from 'vant'

Vue.config.productionTip = false
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(VanImage)
Vue.use(NavBar)
Vue.use(Cell)
Vue.use(List)
Vue.use(Tag)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(ActionSheet)
Vue.use(Divider)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Calendar)
Vue.use(Checkbox)
Vue.use(Overlay)
Vue.use(Card)
Vue.use(Loading);
Vue.use(Step);
Vue.use(Steps);
Vue.use(SubmitBar);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(SwipeCell);

import axios from "axios";
axios.defaults.baseURL =
  process.env.NODE_ENV === "development" ? "" : "https://csoa.tcsoftunion.com/csccapi";

Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')