import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Login.vue')
    },
    {
        path: '/loginout',
        name: 'LoginOut',
        component: () =>
            import ('../views/LoginOut.vue')
    },
    {
        path: "*", 
        redirect: "/"
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router