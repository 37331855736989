import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        sysTitle: '常熟叉车智慧监管平台',
        photoPath: require('../assets/photo.jpg'),
        logoPath: require('../assets/logo.png'),
        weChatAppId: 'wx236af47c8aacac9e', // 请求需要的传入的appid
        weChatUrl: 'https://tctyg.vue.tcsoftunion.com/', //回调的地址,当前项目地址
        userInfo: {
            cdName: '',
            token: '',
            wechatUserCode: ''
        },
        tempQuery:{}
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        }
    }
})